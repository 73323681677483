import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo/SEO';
import Layout from '../../layouts/index';

const Office = (props) => {
  const teams = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-office">
      <SEO
        title="Despacho"
        url="/despacho/"
        description="Comienzos, motivación e historia del despacho Iris Buforn abogados en Oviedo - Asturias"
      />

      <div className="intro-full">
        <div className="container">
          <div className="row">
            <div className="office row justify-content-start">
              <div className="col-12">
                <h1>Despacho</h1>
                <div className="text">
                  <h2>Comienzos</h2>
                  <p>
                    Comienza su andadura en el año 2016, una vez que la letrada Iris Buforn ha cogido
                    la total experiencia a través de colaboraciones con otros despachos de abogados de la capital y
                    se ha formado suficientemente para poder solucionar con la seriedad requerida, y la valentía y
                    la confianza necesaria, los problemas que puedan surgir en los momentos más cotidianos de sus vidas,
                <b> estando en constante búsqueda de la excelencia por carácter, fortaleza, voluntad y rigor</b>.
              </p>
                  <p>
                    En la actualidad, IB ABOGADA ha conseguido tener un despacho con éxito, y la oportunidad de colaborar
                    externamente con otros profesionales del sector.
              </p>
                  <p>
                    Este despacho abarca todos los campos del ámbito jurídico, pero estando altamente <b>especializado en
                procedimientos civiles y penales</b>.
              </p>
                  <p>
                    Asimismo, conocemos las dificultades que atraviesan muchos de nuestros clientes, lo que de ningún modo
                    ha de mermar ni la calidad de servicio, ni la calidad de su defensa letrada. Por eso, hacemos todo lo que
                    está en nuestra mano para que los servicios profesionales ofertados resulten absolutamente asequibles para
                el cliente, ofreciéndoles máximas <b>facilidades de pago</b>.
              </p>
                  <p>
                    A través de la presente página web, se pretende acercarle a la realidad de este despacho, con el propósito
                    que pueda conocer mejor y evaluar la calidad de los servicios ofrecidos por Iris Buforn.
              </p>
                </div>
              </div>
            </div>
          </div>
          <div className="team">
            <h2>Equipo</h2>
            <div className="container pb-6">
              <div className="row">
                {teams.map(edge => (
                  <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
                    <div className="team card-two">
                      <div className="card-header">
                        <div className="card-header-left">
                          {edge.node.frontmatter.image && (
                            <div className="card-image">
                              <img
                                alt={edge.node.frontmatter.title}
                                className="img-fluid mb-2"
                                src={edge.node.frontmatter.image}
                              />
                            </div>
                          )}
                        </div>
                        <div className="card-right">
                          <h3 className="card-title">{edge.node.frontmatter.title}</h3>
                          <ul className="card-meta">
                            <li>
                              <strong>{edge.node.frontmatter.jobtitle}</strong>
                            </li>
                            <li>
                              <a target="_blank" className="card-linkedin" href={edge.node.frontmatter.linkedinurl}>
                                Linkedin
                        </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="team-content"
                        dangerouslySetInnerHTML={{ __html: edge.node.html }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/despacho/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            jobtitle
            linkedinurl
          }
        }
      }
    }
  }
`;

export default Office;
